<template>
  <layout :loading="loading">
    <template #header>
      <h1 class="title-1">Dismissal Warnings</h1>
    </template>
    <div class="dismissal-warnings">
      <div class="dismissal-warnings__header d-flex align-items-center justify-content-end">
        <b-button
          variant="danger"
          @click="openWarningModal"
        >
          <div class="d-flex align-items-center">
            <i class="bx bx-plus font-size-18 mr-2" />
            Add Warning
          </div>
        </b-button>
        <b-button
          v-if="$hasAccess(['admin', 'hrm'])"
          variant="primary"
          class="dismissal-warnings__settings-button icon-button"
          @click="isTemplateModalOpen = true"
        >
          <i class="bx bxs-cog" />
        </b-button>
      </div>
    </div>
    <b-card ref="container" class="dismissal-warnings__container">
      <table class="table table-hover mb-0">
        <thead>
          <th class="dismissal-warnings__employee-column">
            <multiselect
              v-model="filters.from"
              :options="[{ uid: null, name: 'From All' }, ...employees]"
              :searchable="true"
              :show-labels="false"
              :allowEmpty="false"
              label="name"
              track-by="uid"
              placeholder="From"
              class="app-multiselect"
            />
          </th>
          <th class="dismissal-warnings__employee-column">
            <multiselect
              v-model="filters.to"
              :options="[{ uid: null, name: 'To All' }, ...employees]"
              :searchable="true"
              :show-labels="false"
              :allowEmpty="false"
              label="name"
              track-by="uid"
              placeholder="To"
              class="app-multiselect"
            />
          </th>
          <th
            v-if="$hasAccess(['admin', 'hrm'])"
            class="dismissal-warnings__category"
          >
            <dropdown-select
              v-model="filters.category"
              :options="templates"
              valueKey="id"
              label="title"
              allOptionsText="All Categories"
            />
          </th>
          <th class="dismissal-warnings__message">Message</th>
          <th class="dismissal-warnings__date">
            <sort-label
              :sort="sort"
              fieldName="created_at"
              class="justify-content-end w-auto"
              @click.native="handleSortChange('created_at')"
            >
              Date
            </sort-label>
          </th>
          <th class="dismissal-warnings__days-left">
            <sort-label
              :sort="sort"
              fieldName="days_left"
              class="justify-content-end"
              @click.native="handleSortChange('days_left')"
            >
              Days left
            </sort-label>
          </th>
          <th class="dismissal-warnings__status">
            <dropdown-select
              v-model="filters.status"
              right
              class="justify-content-end w-auto"
              :options="['pending', 'dismissed', 'resolved']"
              allOptionsText="All"
            />
          </th>
        </thead>
        <tbody>
          <tr
            v-for="item in list"
            :key="item.id"
            @click="handleRowClick(item.id)"
          >
            <td class="dismissal-warnings__employee-column">
              <div class="dismissal-warnings__employee d-flex align-items-center">
                <b-avatar
                  variant="info"
                  size="30px"
                  :text="getNameInitials(item.from.name)"
                  :src="item.from.photo"
                />
                <span class="dismissal-warnings__user-name">{{ item.from.name }}</span>
              </div>
            </td>
            <td class="dismissal-warnings__employee-column">
              <div class="dismissal-warnings__employee d-flex align-items-center">
                <b-avatar
                  variant="info"
                  size="30px"
                  :text="getNameInitials(item.to.name)"
                  :src="item.to.photo"
                />
                <span class="dismissal-warnings__user-name">{{ item.to.name }}</span>
              </div>
            </td>
            <td
              v-if="$hasAccess(['admin', 'hrm'])"
              class="dismissal-warnings__category"
            >
              {{ item.category.title }}
            </td>
            <td class="dismissal-warnings__message">
              <div>
                {{ get(item, 'messages[0].message', '') }}
              </div>
            </td>
            <td class="dismissal-warnings__date">{{ $moment(item.created_at).format('DD.MM.YYYY') }}</td>
            <td class="dismissal-warnings__days-left">{{ getDaysLeft(item.days_left) }}</td>
            <td class="dismissal-warnings__status">
              <status-badge :status="item.status" />
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="!list.length"
        class="dismissal-warnings__empty-list"
      >
        <img src="@/assets/images/сhecklist.svg" alt="" />
      </div>
    </b-card>
    <pagination
      :page="page"
      :limit="limit"
      :totalRecordsCount="totalRecordsCount"
      @onPageChange="handlePageChange"
      @onLimitChange="handleLimitChange"
    />
    <chat-modal
      v-model="isChatModalOpen"
      :warningId="selectedWarningId"
      @onStatusChange="handleStatusChange"
    />
    <warning-modal
      v-model="isWarningModalOpen"
      @onSubmit="handleCreateDissmissalWarning"
    />
    <template-modal v-model="isTemplateModalOpen" />
  </layout>
</template>

<script>
import get from 'lodash/get';
import { mapActions } from 'vuex';
import { getNameInitials } from '@/utils/user';
import { WARNING_PERIOD } from '@/utils/constants';
import SortLabel from '@/components/sort-label.vue';
import Pagination from '@/components/pagination.vue';
import DropdownSelect from '@/components/dropdown-select.vue';
import ChatModal from '@/components/dismissal-warning/chat-modal.vue';
import StatusBadge from '@/components/dismissal-warning/status-badge.vue';
import WarningModal from '@/components/dismissal-warning/warning-modal.vue';
import TemplateModal from '@/components/dismissal-warning/templates-modal.vue';

export default {
  components: {
    SortLabel,
    ChatModal,
    Pagination,
    StatusBadge,
    WarningModal,
    TemplateModal,
    DropdownSelect
  },
  data() {
    return {
      page: 1,
      limit: 10,
      loading: false,
      isChatModalOpen: false,
      isWarningModalOpen: false,
      isTemplateModalOpen: false,
      selectedWarningId: null,
      filters: {
        from: { uid: null, name: 'From All' },
        to: { uid: null, name: 'To All' },
        category: { id: null, title: 'All Categories' },
        status: null
      },
      sort: {
        field: 'created_at',
        direction: 'desc',
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.handleFetchDismissalWarnings();
    await this.getAllEmployees();
    await this.getSubordinates();
    await this.getDismissalTemplates();
    this.loading = false;
  },
  methods: {
    get,
    getNameInitials,
    ...mapActions('employees', ['getSubordinates', 'getAllEmployees']),
    ...mapActions('dismissalWarning', [
      'getDismissalWarnings',
      'getDismissalTemplates',
      'createDismissalWarning',
    ]),
    async handleFetchDismissalWarnings() {
      await this.getDismissalWarnings(this.requestParams);
    },
    async handleStatusChange() {
      this.loading = true;
      await this.handleFetchDismissalWarnings(this.requestParams);
      this.loading = false;
    },
    openWarningModal() {
      this.isWarningModalOpen = true;
    },
    async handleCreateDissmissalWarning(data) {
      this.loading = true;
      await this.createDismissalWarning({
        message: data.message,
        to_employee_uid: data.employee.uid,
        dismissal_warning_category_id: data.template.id
      });
      await this.handleFetchDismissalWarnings(this.requestParams);
      this.loading = false;
    },
    handleRowClick(id) {
      this.selectedWarningId = id;
      this.isChatModalOpen = true;
    },
    async handlePageChange(page) {
      this.page = page;
      this.loading = true;
      await this.handleFetchDismissalWarnings(this.requestParams);
      this.loading = false;
    },
    async handleLimitChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.loading = true;
      await this.handleFetchDismissalWarnings(this.requestParams);
      this.loading = false;
    },
    handleSortChange(field) {
      if (this.sort.field === field) {
        if (this.sort.direction === 'asc') {
          this.sort.direction = 'desc';
        } else if (this.sort.direction === 'desc') {
          this.sort.field = '';
          this.sort.direction = '';
        }
      } else {
        this.sort.field = field;
        this.sort.direction = 'asc';
      }
    },
    getDaysLeft(days) {
      const diff = WARNING_PERIOD - days;
      return diff > 0 ? diff : 0;
    },
  },
  computed: {
    list() {
      return this.$store.state.dismissalWarning.warnings;
    },
    totalRecordsCount() {
      return this.$store.state.dismissalWarning.totalItems;
    },
    subordinates() {
      return this.$store.state.employees.subordinates;
    },
    allEmployees() {
      return this.$store.state.employees.allEmployees;
    },
    employees() {
      return this.$hasAccess(['admin', 'hrm']) ? this.allEmployees : this.subordinates;
    },
    templates() {
      return this.$store.getters['dismissalWarning/activeTemplates'];
    },
    requestSortParam() {
      return this.sort.field
        ? { [`sort[${this.sort.field}]`]: this.sort.direction }
        : {};
    },
    requestParams() {
      return {
        page: this.page,
        per_page: this.limit,
        from_employee_uid: this.filters.from?.uid,
        to_employee_uid: this.filters.to?.uid,
        dismissal_warning_category_id: this.filters.category?.id,
        status: this.filters.status,
        ...this.requestSortParam
      }
    }
  },
  watch: {
    filters: {
      async handler() {
        this.page = 1;
        this.loading = true;
        await this.handleFetchDismissalWarnings(this.requestParams);
        this.loading = false;
      },
      deep: true
    },
    sort: {
      async handler() {
        this.loading = true;
        await this.handleFetchDismissalWarnings(this.requestParams);
        this.loading = false;
      },
      deep: true
    } 
  }
}
</script>

<style lang="scss" scoped>
.app-multiselect {
  top: 5px;
  left: 20px;
  position: absolute;
  width: calc(100% - 40px);
}
.dismissal-warnings {
  &__header {
    margin-bottom: 20px;
  }
  &__settings-button {
    margin-left: 10px;
    i {
      font-size: 16px;
    }
  }
  &__user-name {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 20px;
  }
  &__container {
    .card-body {
      padding: 20px 0px;
    }
  }
  &__employee-column {
    width: 250px;
  }
  &__empty-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 300px);
  }
  &__date {
    width: 115px;
    text-align: right;
  }
  &__days-left {
    width: 116px;
    text-align: right;
  }
  &__message {
    min-width: 110px;
    div {
      min-width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__employee {
    min-width: 100px;
  }
  &__status {
    width: 100px;
    text-align: right;
  }
  &__category {
    width: 180px;
  }

  @media(max-width: 1500px) {
    &__category {
      display: none;
    }
  }

  @media(max-width: 1300px) {
    &__employee-column {
      width: 180px;
    }
  }

  @media(max-width: 1110px) {
    &__days-left {
      display: none;
    }
    &__message {
      width: 200px;
    }
  }
}
@media (max-width: 1100px) {
  .card-body {
    overflow: scroll;
  }
}
.table {
  table-layout: fixed;
  td {
    padding: 15px 20px;
    vertical-align: middle;
    @media(max-width: 1500px) {
      padding: 10px 15px;
    }
  }
  th {
    border-top: none;
    padding: 15px 20px;
    position: relative;
    font-weight: 500;
    color: #2A3042;
  }
}
</style>
